import React, { HTMLAttributes, ReactElement, useRef, useState } from "react"
import { useOnClickOutside } from "../hooks/useOnClickOutside"

interface ButtonProps {
  className?: string
  forwardRef?: React.MutableRefObject<any>
}

const Button = ({
  className,
  forwardRef,
  ...rest
}: ButtonProps & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={`m-1 py-2 px-4 rounded transition-colors duration-150 font-light tracking-wide ${className}`}
      ref={forwardRef}
      {...rest}
    />
  )
}

interface DropdownButtonProps {
  activeClassName?: string
  children?: ReactElement
  className?: string
  contents?: ReactElement
}

const DropdownButton = ({
  activeClassName,
  children,
  className,
  contents,
  ...rest
}: DropdownButtonProps & HTMLAttributes<HTMLButtonElement>) => {
  const [display, setDisplay] = useState(false),
    ref = useRef(),
    toggleDisplay = () => setDisplay(!display)

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setDisplay(false))

  return (
    <Button
      className={`inline-flex flex-col justify-between items-start ${className} ${
        display ? activeClassName : ``
      }`}
      onClick={toggleDisplay}
      forwardRef={ref}
      {...rest}
    >
      <div className="inline-flex">{children}</div>
      <div
        className="static sm:absolute sm:-z-1 left-0 top-100 self-start inline-flex flex-col items-stretch w-full ml-2 sm:ml-0 sm:mt-8"
        style={display ? {} : { maxHeight: `0`, overflow: `hidden` }}
      >
        {contents}
      </div>
    </Button>
  )
}

export { Button, DropdownButton }
