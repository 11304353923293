import React, { MouseEvent, useRef, useState, useEffect } from "react"
import { usePortal } from "../hooks/usePortal"

type ReturnsJSXElement = (close: () => void) => JSX.Element | JSX.Element[]

const Note = ({
  callback,
  children,
  className,
  disappear,
}: {
  callback?: () => void
  children: JSX.Element | JSX.Element[] | ReturnsJSXElement
  className?: string
  disappear?: number | null
}) => {
  const [open, setOpen] = useState(false),
    openRef = useRef(open),
    ref = useRef<HTMLDivElement>(null),
    close = (e?: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (openRef.current) {
        setOpen(false)
        if (callback) callback()
      }
    },
    note = (
      <div
        className={`note-container pointer-events-auto m-4 mt-0 w-full sm:w-3/4 md:w-1/2 xl:w-1/4 transform translate-x-full ${
          open ? `open` : `closed`
        }`}
        ref={ref}
      >
        <div
          className={`note relative ml-8 sm:ml-4 p-4 rounded bg-gray-100 ${className}`}
          style={{ minHeight: `4rem` }}
        >
          {typeof children === `function` ? children(close) : children}
          {/* <Button
            className="-m-1 mt-2 pt-1 pr-1 pb-1 pl-1 inline-flex items-center justify-center text-xs border border-gray-400 hover:border-gray-200 hover:bg-gray-200 text-gray-500 hover:text-gray-900"
            onClick={close}
          >
            <>
              <span
                  dangerouslySetInnerHTML={{
                    __html: feather.icons[`x`].toSvg({
                      class: `w-3 mr-1`,
                    }),
                  }}
                />
              Dismiss
            </>
          </Button> */}
        </div>
      </div>
    )

  useEffect(() => {
    openRef.current = open
  }, [open])

  useEffect(() => {
    // Animate in
    setOpen(true)

    // Start timer to disappear if set
    let timeout: NodeJS.Timeout | undefined
    if (typeof disappear === `number`)
      timeout = setTimeout(() => close(), disappear)

    // Clear timer on unmount
    return () => timeout && clearTimeout(timeout)
  }, [])

  useEffect(() => {
    // Set max height so we can animate out
    if (ref?.current?.offsetHeight)
      ref.current.style.maxHeight = `${Math.ceil(ref.current.offsetHeight)}px`
  })

  return (
    usePortal(note, {
      className: `fixed z-20 pointer-events-none inset-0 pt-24 sm:pt-4 flex flex-col items-end justify-start`,
    }) || null
  )
}

export default Note
export { Note }
