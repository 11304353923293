import { graphql, PageProps } from "gatsby"
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { Helmet } from "react-helmet"
import sudoku from "sudoku-umd"
import feather from "feather-icons"

import { Button, DropdownButton } from "../components/buttons"
import { Note } from "../components/note"

import { useLocalStorage } from "../hooks/useLocalStorage"
import { useOnClickOutside } from "../hooks/useOnClickOutside"
import { usePrevious } from "../hooks/usePrevious"

type UserDifficulty =
  | `Easy`
  | `Medium`
  | `Hard`
  | `Tough`
  | `Insane`
  | `Inhuman`
type GameDifficulty =
  | `easy`
  | `medium`
  | `hard`
  | `very-hard`
  | `insane`
  | `inhuman`
  | number
type GameStatus = `inactive` | `started` | `error` | `incomplete` | `complete`
type userGameDifficultyMap = { [K in UserDifficulty]: GameDifficulty | number }
const userGameDifficultyMap: userGameDifficultyMap = {
  Easy: `easy`,
  Medium: `medium`,
  Hard: `hard`,
  Tough: `very-hard`,
  Insane: `insane`,
  Inhuman: `inhuman`,
}

type ReturnsJSXElement = (close: () => void) => JSX.Element | JSX.Element[]

type NoteProps = {
  body: (string | JSX.Element | ReturnsJSXElement)[]
  disappear?: number | null
  head: string | JSX.Element
  timestamp?: number
  type: `error` | `warn` | `success`
}

const colors = [
  `ball-red`,
  `ball-blue`,
  `ball-purple`,
  `ball-orange`,
  `ball-green`,
  `ball-yellow`,
  `ball-lime`,
  `ball-lilac`,
  `ball-baby`,
]

const messages: { [key: string]: string[] } = {
  complete: [
    `You've done it!`,
    `Simply incredible!`,
    `Wild applause!`,
    `You're the best!`,
    `Well done brave mind adventurer!`,
    `Magnificent!`,
    `Well played!`,
  ],
  error: [
    `Hmm.. Something's wrong.`,
    `Ah crap.`,
    `Uh, not quite there yet.`,
    `Bad luck, pal.`,
    `Well that's too bad..`,
    `Unfortunately not.`,
    `You seem to be incorrect.`,
    `No dice, bud.`,
    `Terribly sorry.`,
  ],
  inactive: [
    `Wanna play a game?`,
    `How about Sudoku in color?`,
    `A rousing game of Sudoku, perhaps?`,
  ],
  incomplete: [
    `Doing good so far!`,
    `All's well that ends well!`,
    `By gum you're on to something!`,
    `Keep at it, meatsack!`,
    `Nothing wrong!`,
    `No mistakes to speak of!`,
  ],
  reset: [`Have another shot.`, `Okay, try again.`],
  started: [
    `Go get 'em tiger!`,
    `You can do it!`,
    `Away we go!`,
    `Let's see what you can do.`,
    `Okay, show me what you've got.`,
    `I mean, you can try..`,
    `Why not?`,
  ],
}

const Index = ({ data, location, navigate }: PageProps): ReactElement => {
  const { host, pathname } = location,
    [board, _setBoard] = useLocalStorage(`board`, ``),
    [gameBoard, setGameBoard] = useLocalStorage(`game`, board),
    prevGameBoard = usePrevious(gameBoard),
    [status, setStatus] = useLocalStorage(
      `status` as GameStatus,
      board === `` ? `inactive` : `started`
    ),
    prevStatus = usePrevious(status),
    [notes, setNotes] = useState<NoteProps[]>([]),
    notesRef = useRef<NoteProps[]>(notes),
    notify = useCallback((note: NoteProps) => {
      const newNote = Object.assign({}, note, {
        timestamp: new Date().getTime(),
      })
      notesRef.current = notesRef.current.concat([newNote])
      setNotes(notesRef.current)
    }, []),
    dismiss = (timestamp: number) => {
      setTimeout(() => {
        setTimeout(
          () =>
            (notesRef.current = notesRef.current.filter(
              (note) => note.timestamp !== timestamp
            )),
          0
        )
        setNotes(notesRef.current)
      }, 1000)
    },
    setBoard = (board: string) => {
      _setBoard(board)
      setGameBoard(board)
    },
    resetBoard = () => {
      if (board === `` || gameBoard === board) return

      setGameBoard(board)
      setStatus(`started`)
      notify({
        type: `success`,
        head: `Back to the beginning.`,
        body: [communicate(`reset`)],
      })
    },
    place = (cell: number, color: number) => {
      const newGameBoard = gameBoard.split(``)
      newGameBoard[cell] = `${color}`
      setGameBoard(newGameBoard.join(``))
    },
    remove = (cell: number) => {
      // Don't remove colors from the original board
      if (board.split(``)[cell] !== `.`) return

      const newGameBoard = gameBoard.split(``)
      newGameBoard[cell] = `.`

      setGameBoard(newGameBoard.join(``))
    },
    check = useCallback(
      (explicit?: boolean) => {
        if (board === ``) return

        const solutionArray = sudoku.solve(board).split(``),
          gameArray = gameBoard.split(``),
          complete = gameArray.indexOf(`.`) === -1
        let error = 0

        solutionArray.forEach((value: string, cell: number) => {
          // If the solution value differs from the game array increment errors unless the game array is empty
          value !== gameArray[cell] && gameArray[cell] !== `.` && error++
        })

        // Mention errors when check is performed manually or game is complete
        const newStatus =
          explicit || complete
            ? error
              ? `error`
              : complete
              ? `complete`
              : `incomplete`
            : status

        // Update status when it changes
        if (status !== newStatus) setStatus(newStatus)
        // If not, notify anyway when explicit is true
        else if (explicit)
          notify({
            head: communicate(status),
            body: [],
            type: status ? `success` : `error`,
          })
      },
      [board, gameBoard, notify, status, setStatus]
    ),
    share = () => {
      if (board === ``) return

      navigator.clipboard
        .writeText(`${host}/${board.replace(/\./g, `0`)}`)
        .then(() => {
          notify({
            type: `success`,
            head: `Game URL copied to clipboard.`,
            body: [
              `Paste the URL to share with a friend! Thanks for sharing the fun!`,
            ],
          })
        })
        .catch(console.error)
    },
    newGame = ({ difficulty }: { difficulty: UserDifficulty }) => {
      console.log(data)
      const d = data as any
      const randomIndex = Math.floor(
          Math.random() * d[difficulty.toLowerCase()].edges.length
        ),
        randomBoard = d[difficulty.toLowerCase()].edges[randomIndex].node.board

      console.log(difficulty.toLowerCase(), d[difficulty.toLowerCase()])
      console.log(randomBoard)
      setBoard(randomBoard)
      setStatus(`started`)

      notify({
        type: `success`,
        head: `New game started. (${difficulty}.)`,
        body: [communicate(`started`)],
      })
    },
    communicate = (type: string) => {
      const index = Math.floor(Math.random() * messages[type].length),
        message = messages[type][index]
      return message
    },
    [menu, setMenu] = useState(false),
    menuRef = useRef<HTMLElement>(null),
    toggleMenu = () => setMenu(!menu)

  // Update notes ref whenever state chages
  useEffect(() => {
    notesRef.current = notes
  }, [notes])

  // Be vocal about status chages
  useEffect(() => {
    if (status === prevStatus) return
    notify({
      head: communicate(status),
      body: [],
      type: status ? `success` : `error`,
    })
  }, [status, prevStatus, notify])

  // Check the game board when it changes
  useEffect(() => {
    if (prevGameBoard && prevGameBoard !== gameBoard) check()
  }, [gameBoard, prevGameBoard, check])

  // Read board URLs
  useEffect(() => {
    // This is a known URL..
    if ([`/`].indexOf(pathname) > -1) return

    // Validate
    const valid = /[\d]{81}/.test(pathname)

    // This board URL isn't good
    if (!valid) {
      console.log(`Invalid board url`)
      // Navigate so we stop reading share
      navigate(`/`)
      // Offer a warning
      notify({
        type: `error`,
        head: `Board URL failed to open.`,
        body: [
          `The URL you followed doesn't contain a game. Did somebody copy incorrectly?`,
        ],
      })
      // Break
      return
    }

    const setSharedBoard = () => {
      // Update the board
      setBoard(pathname.substring(1).replace(/0/g, `.`))
      // Set status
      setStatus(`started`)
      // Navigate so we stop reading share
      navigate(`/`)
      // Offer a success message
      notify({
        type: `success`,
        head: `Shared game opened successfully.`,
        body: [`Thanks for sharing! Have fun!`],
      })
    }

    // If no current board exists, skip ahead
    // Otherwise prompt user so they have a chance to keep their current game
    if (board === ``) setSharedBoard()
    else {
      notify({
        type: `warn`,
        head: `Overwrite current game?`,
        body: [
          `Loading a shared game will cause you to lose your current one. Do you want to proceed?`,
          (close) => (
            <Button
              className="bg-gray-200 hover:text-gray-900"
              onClick={() => {
                close()
                navigate(`/`)
              }}
            >
              Keep current game
            </Button>
          ),
          (close) => (
            <Button
              className="border-2 border-gray-200 hover:bg-gray-200 hover:text-gray-900"
              onClick={() => {
                setSharedBoard()
                close()
              }}
            >
              Load shared game
            </Button>
          ),
        ],
        disappear: null,
      })
    }
  }, [])

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(menuRef, () => setMenu(false))

  return (
    <div className="p-4 pt-24 lg:px-8 font-light tracking-wide">
      <Helmet>
        <title>Peas. Sudoku in color.</title>
        <meta name="description" content="Play sudoku in color." />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png?v=7k4OyB5dnv"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png?v=7k4OyB5dnv"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png?v=7k4OyB5dnv"
        />
        <link rel="manifest" href="/site.webmanifest?v=7k4OyB5dnv" />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg?v=7k4OyB5dnv"
          color="#b5ed6d"
        />
        <link rel="shortcut icon" href="/favicon.ico?v=7k4OyB5dnv" />
        <meta name="msapplication-TileColor" content="#00a300" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      {notes.map((note) => {
        return (
          <Note
            key={note.timestamp}
            className={`${note.type} text-gray-800`}
            callback={() => dismiss(note.timestamp as number)}
            disappear={
              note.disappear === null
                ? null
                : note.disappear
                ? note.disappear
                : 6000
            }
          >
            {(close) => (
              <>
                <h2 className="my-1 text-base font-light tracking-wide text-gray-800">
                  {note.head}
                </h2>
                <>
                  {note.body.map((bodyContent) => (
                    <p className="mt-2 text-sm font-light text-gray-800">
                      {typeof (bodyContent as ReturnsJSXElement) !== `function`
                        ? bodyContent
                        : (bodyContent as ReturnsJSXElement)(close)}
                    </p>
                  ))}
                </>
              </>
            )}
          </Note>
        )
      })}
      <header
        className="fixed z-30 top-4 left-1/2 transform -translate-x-1/2 bg-gray-100 border sm:border-none border-gray-400 text-gray-600 p-2 rounded max-w-2xl"
        style={{ width: `calc(100% - 2rem)` }}
      >
        <nav ref={menuRef}>
          <div className="inline-flex flex-col w-full">
            <Button
              className={`inline-flex items-center sm:hidden hover:bg-gray-200 hover:text-gray-900 ${
                menu ? `bg-gray-200` : ``
              }`}
              onClick={toggleMenu}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: feather.icons[`menu`].toSvg({ class: `w-4 mr-2` }),
                }}
              />
              Menu
            </Button>
            <div
              className={`${menu ? `inline-flex flex-col` : `hidden`} sm:block`}
            >
              <div className="inline-flex flex-col">
                <DropdownButton
                  className={`group relative hover:bg-gray-200 hover:text-gray-900 hover:rounded-b-none ${
                    status === `inactive` ? `bg-gray-200` : ``
                  }`}
                  activeClassName={`bg-gray-200 rounded-b-none`}
                  contents={
                    <>
                      <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4"
                        onClick={() => newGame({ difficulty: `Easy` })}
                      >
                        Easy
                      </button>
                      <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4"
                        onClick={() => newGame({ difficulty: `Medium` })}
                      >
                        Medium
                      </button>
                      <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4"
                        onClick={() => newGame({ difficulty: `Hard` })}
                      >
                        Hard
                      </button>
                      <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4"
                        onClick={() => newGame({ difficulty: `Tough` })}
                      >
                        Tough
                      </button>
                      <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4"
                        onClick={() => newGame({ difficulty: `Insane` })}
                      >
                        Insane
                      </button>
                      <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4"
                        onClick={() => newGame({ difficulty: `Inhuman` })}
                      >
                        Inhuman
                      </button>
                      {/* <button
                        className="bg-gray-200 text-gray-600 hover:text-gray-900 text-left text-sm font-light tracking-wide p-1 pl-4 rounded-b"
                        onClick={() => newGame({ difficulty: 81 })}
                      >
                        Custom
                        <input
                          type="text"
                          value=""
                          placeholder="17–81"
                          onClick={e => e.stopPropagation()}
                          className="hidden w-12 ml-1 px-1 rounded text-center"
                        />
                      </button> */}
                    </>
                  }
                >
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: feather.icons[`plus-circle`].toSvg({
                          class: `w-4 mr-2`,
                        }),
                      }}
                    />
                    New Game
                  </>
                </DropdownButton>
              </div>
              <Button
                onClick={resetBoard}
                className={`inline-flex items-center hover:bg-gray-200 hover:text-gray-900`}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: feather.icons[`rotate-ccw`].toSvg({
                      class: `w-4 mr-2`,
                    }),
                  }}
                />
                Reset
              </Button>
              <Button
                onClick={() => check(true)}
                className={`inline-flex items-center hover:bg-gray-200 hover:text-gray-900`}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: feather.icons[`check-circle`].toSvg({
                      class: `w-4 mr-2`,
                    }),
                  }}
                />
                Check
              </Button>
              <Button
                className={`inline-flex items-center hover:bg-gray-200 hover:text-gray-900`}
                onClick={share}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: feather.icons[`share`].toSvg({
                      class: `w-4 mr-2`,
                    }),
                  }}
                />
                Share
              </Button>
            </div>
          </div>
        </nav>
      </header>
      <main className="flex justify-center">
        <div className="flex-grow grid grid-cols-9 max-w-2xl">
          {gameBoard.split(``).map((contents: string, index: number) => (
            <Cell
              board={board}
              colors={colors}
              contents={contents}
              index={index}
              place={place}
              remove={remove}
            />
          ))}
        </div>
      </main>
    </div>
  )
}

interface CellProps {
  board: string
  colors: string[]
  contents: string
  index: number
  place: (cell: number, color: number) => void
  remove: (cell: number) => void
}

const Cell = ({ board, colors, contents, index, place, remove }: CellProps) => {
  const empty = contents === `.`,
    immutable = !empty && board[index] === contents,
    value = !empty ? parseInt(contents, 10) - 1 : undefined,
    background = !empty ? `bg-${colors[value as number]}` : ``,
    [active, setActive] = useState(false),
    [placed, setPlaced] = useState(false),
    toggleActive = () => setActive(!active),
    setInactive = () => setActive(false),
    handleMouseLeave = () => (placed ? setPlaced(false) : undefined)

  return (
    <div
      className="cell group relative inline-flex items-center justify-center border-t border-l border-gray-200"
      data-value={index}
      onMouseLeave={handleMouseLeave}
    >
      <div className="invisible h-0 pb-full">{index}</div>
      {immutable ? (
        <GameBall background={background} />
      ) : empty ? (
        active ? (
          <ColorPicker
            cell={index}
            colors={colors}
            place={(cell: number, color: number) => {
              place(cell, color)
              setPlaced(true)
            }}
            setInactive={setInactive}
          />
        ) : (
          <EmptySpace toggleActive={toggleActive} />
        )
      ) : (
        <UserBall
          background={background}
          index={index}
          remove={remove}
          placed={placed}
        />
      )}
    </div>
  )
}

interface GameBallProps {
  background: string
}

const GameBall = ({ background }: GameBallProps) => (
  <div
    className={`ball absolute w-2/3 h-0 pb-2/3 rounded-full ${background}`}
  />
)

interface UserBallProps {
  background: string
  index: number
  remove: (cell: number) => void
  placed?: boolean
}

const UserBall = ({ background, index, remove, placed }: UserBallProps) => (
  <button
    className={`ball absolute w-2/3 h-0 pb-2/3 rounded-full ${background} ${
      placed ? `` : `hover:bg-gray-400`
    }`}
    onClick={() => remove(index)}
  />
)

interface EmptySpaceProps {
  toggleActive: () => void
}

const EmptySpace = ({ toggleActive }: EmptySpaceProps) => (
  <div className="absolute w-full h-full inline-flex items-center justify-center">
    <button
      className="empty w-2/3 h-2/3 bg-gray-100 hover:bg-gray-200 rounded-full text-transparent"
      onMouseEnter={toggleActive}
    ></button>
  </div>
)

interface ColorPickerProps {
  cell: number
  colors: string[]
  place: (cell: number, color: number) => void
  setInactive: () => void
}

const ColorPicker = ({
  cell,
  colors,
  place,
  setInactive,
}: ColorPickerProps) => (
  <div
    className="picker opacity-0 group-hover:opacity-100 group-focus:opacity-100 absolute w-full h-full inline-grid grid-cols-3"
    onMouseLeave={setInactive}
  >
    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
      <span
        className="ball relative inline-flex items-center justify-center"
        data-value={index}
      >
        {index}
        <button
          className={`absolute w-2/3 h-0 pb-2/3 rounded-full bg-${colors[index]}`}
          onClick={() => place(cell, index + 1)}
        />
      </span>
    ))}
  </div>
)

export default Index

export const boardsQuery = graphql`
  query {
    easy: allBoardsCsv(filter: { difficulty: { eq: "easy" } }) {
      edges {
        node {
          board
          difficulty
          empty
        }
      }
    }
    medium: allBoardsCsv(filter: { difficulty: { eq: "medium" } }) {
      edges {
        node {
          board
          difficulty
          empty
        }
      }
    }
    hard: allBoardsCsv(filter: { difficulty: { eq: "hard" } }) {
      edges {
        node {
          board
          difficulty
          empty
        }
      }
    }
    tough: allBoardsCsv(filter: { difficulty: { eq: "very-hard" } }) {
      edges {
        node {
          board
          difficulty
          empty
        }
      }
    }
    inhuman: allBoardsCsv(filter: { difficulty: { eq: "inhuman" } }) {
      edges {
        node {
          board
          difficulty
          empty
        }
      }
    }
  }
`
